import './App.scss';
import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Profile from 'pages/Profile';
import { store } from 'store';
import { DEFAULT_QUERY_OPTION } from 'utils/constant';
import 'react-toastify/dist/ReactToastify.css';

const App: React.FC = () => (
  <BrowserRouter>
    <Suspense fallback={<div>Loading..</div>}>
      <Routes>
        <Route path="/" element={<Profile />} />
        <Route path="*" element={<div>Not Found</div>} />
      </Routes>
    </Suspense>
  </BrowserRouter>

);

const QueryWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        ...DEFAULT_QUERY_OPTION,
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      {children}
    </QueryClientProvider>
  );
};
const AppWrapper: React.FC = () => (
  <Provider store={store}>
    <QueryWrapper>
      <App />
      <ToastContainer />
    </QueryWrapper>
  </Provider>
);
export default AppWrapper;
