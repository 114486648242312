import DOMPurify from 'dompurify';
import React from 'react';

import mapModifiers from 'utils/functions';

export type Sizes = '20x28' | '24x32';

export type ModifiersHeading = (GeneralTextStyle | Sizes | FontFamily)[];
interface HeadingProps {
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  children?: React.ReactNode;
  content?: string;
  modifiers?: ModifiersHeading;
}

const Heading: React.FC<HeadingProps> = ({
  type = 'h5',
  content,
  modifiers,
  children,
}) => {
  const Element = type;
  return (
    content ? (
      <Element
        className={mapModifiers('a-heading', modifiers)}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
      />
    ) : (
      <Element
        className={mapModifiers('a-heading', modifiers)}
      >
        {children}
      </Element>
    )
  );
};

Heading.defaultProps = {
  children: undefined,
};

export default Heading;
