import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  useInfiniteQuery, useMutation, useQueryClient
} from 'react-query';
import { toast } from 'react-toastify';

import ProjectProfileCardsContainer from './cards';
import ProjectProfileDetailContainer from './detail';

import Loading from 'components/atoms/Loading';
import Text from 'components/atoms/Text';
import { getProjectProfilesService, updateProfilePGService } from 'services/profiles';
import { ProfileData } from 'services/profiles/types';

interface FormDataType {
  note: string;
}

interface ProjectProfileContentContainerProps {
  projectCode: string;
  activeStatus: number;
}

const ProjectProfileContentContainer: React.FC<ProjectProfileContentContainerProps> = ({
  projectCode,
  activeStatus,
}) => {
  //* Hooks
  const queryClient = useQueryClient();

  //* States
  const [activeId, setActiveId] = useState(0);

  //* React hook form
  const method = useForm<FormDataType>({
    defaultValues: {
      note: ''
    }
  });

  //* React query
  const {
    data: profilesRes,
    isFetching: profilesLoading,
    hasNextPage: profilesHasNextPage,
    fetchNextPage: profilesLoadMore
  } = useInfiniteQuery(
    ['project-profiles', 'profiles', projectCode, activeStatus],
    ({ pageParam = 1 }) => getProjectProfilesService({
      projectCode: projectCode || '',
      status: activeStatus,
      page: pageParam,
    }),
    {
      enabled: !!projectCode,
      getNextPageParam: (lastPage) => (
        lastPage.meta.page < lastPage.meta.totalPages
          ? lastPage.meta.page + 1 : undefined),
    },
  );

  const { mutate: updateMutate, isLoading: updateLoading } = useMutation(updateProfilePGService, {
    onSuccess: () => {
      toast.success(<Text modifiers={['16x24', '600', 'eerieBlack']}>Thông tin đã được cập nhật</Text>, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      queryClient.invalidateQueries(['project-profiles']);
      method.reset();
    },
    onError: () => {
      toast.error(<Text modifiers={['16x24', '600', 'eerieBlack']}>Đã có lỗi xảy ra</Text>, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
  });

  //* Datas
  const profilesList = useMemo(() => {
    if (profilesRes) {
      let list: ProfileData[] = [];
      profilesRes.pages.forEach((ele) => {
        list = [...list, ...ele.data];
      });
      return list;
    }
    return [];
  }, [profilesRes]);

  const profileDetail = useMemo(
    () => profilesList.find((item) => item.pivot.pgId === activeId),
    [profilesList, activeId]
  );

  //* Funtions
  const onSubmit = (status: number, data: FormDataType) => {
    if (projectCode) {
      updateMutate({
        projectCode,
        spId: activeId,
        requestFormData: {
          note: data.note,
          status
        }
      });
    }
  };

  //* Effects
  useEffect(() => {
    if (profilesList?.[0]) {
      setActiveId(profilesList[0].pivot.pgId);
    }
  }, [activeStatus, profilesList]);

  return (
    <div className="p-profile_content">
      {(profilesLoading) && (
        <div className="p-profile_loading">
          <Loading isShow />
        </div>
      )}
      <div className="p-profile_listProfile">
        <ProjectProfileCardsContainer
          activeProfileId={activeId}
          profiles={profilesList}
          handleGetIndexActive={setActiveId}
          handleLoadMore={() => {
            if (profilesHasNextPage) {
              profilesLoadMore();
            }
          }}
        />
      </div>
      <div className="p-profile_informationDetail">
        <FormProvider {...method}>
          <ProjectProfileDetailContainer
            detail={profileDetail}
            loading={updateLoading}
            handleAction={(status) => method.handleSubmit((data) => onSubmit(status, data))()}
          />
        </FormProvider>
      </div>
    </div>
  );
};

export default React.memo(ProjectProfileContentContainer);
