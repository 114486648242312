export const PG_STATUS = {
  PG_STATUS_NOT_APPROVE_YET: 0,
  PG_STATUS_APPROVED: 1,
  PG_STATUS_BACKUP: 2,
  PG_STATUS_REJECT: 3,
};

export const DEFAULT_QUERY_OPTION = {
  retry: 0,
  refetchOnMount: true,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
};

export const BASE_URL = process.env.REACT_APP_BASE_URL || '';
