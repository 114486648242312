import React from 'react';

import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import mapModifiers, { convertHeight } from 'utils/functions';

export interface ProfileCardProps {
  id?: number;
  img?: string;
  name?: string;
  age?: string;
  weight?: number;
  height?: number;
  active?: boolean;
  handleClick?: (id?: number) => void;
}

const ProfileCard: React.FC<ProfileCardProps> = ({
  img,
  name,
  age,
  weight,
  height,
  active,
  handleClick,
  id
}) => (
  <div
    className={mapModifiers('m-profileCard', active && 'active')}
    onClick={() => handleClick && handleClick(id)}
  >
    <div className="m-profileCard_img">
      <Image src={img || ''} alt={name} ratio="98x120" />
    </div>
    <div className="m-profileCard_info">
      <div>
        <Text modifiers={['14x20', '600', 'outerSpace']}>{name}</Text>
      </div>
      <div className="u-mt-4">
        <Text type="span" modifiers={['14x20', '600', 'gray']}>Chiều cao:</Text>
        {' '}
        <Text type="span" modifiers={['14x20', '600', 'outerSpace']}>{convertHeight(height)}</Text>
      </div>
      <div className="u-mt-4">
        <Text type="span" modifiers={['14x20', '600', 'gray']}>Cân nặng:</Text>
        {' '}
        <Text type="span" modifiers={['14x20', '600', 'outerSpace']}>
          {weight}
          kg
        </Text>
      </div>
      <div className="u-mt-4">
        <Text type="span" modifiers={['14x20', '600', 'gray']}>Tuổi:</Text>
        {' '}
        <Text type="span" modifiers={['14x20', '600', 'outerSpace']}>{age}</Text>
      </div>
    </div>
  </div>
);

export default ProfileCard;
