import DOMPurify from 'dompurify';
import React from 'react';

import mapModifiers from 'utils/functions';

type Size = '12x16' | '13x18' | '14x20' | '16x24' | '20x28';

export type TypeModifiers = (Size | FontFamily | ColorStyle | FontWeight | TextStyle)[];
interface TextProps {
  children?: React.ReactNode;
  content?: string;
  type?: 'p' | 'span' | 'div';
  modifiers?: TypeModifiers;
}

const Text: React.FC<TextProps> = ({
  children,
  content,
  type = 'p',
  modifiers,
}) => {
  const Element = type;
  return content ? (
    <div
      className={mapModifiers('a-text', modifiers)}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
    />
  ) : (
    <Element className={mapModifiers('a-text', modifiers)}>{children}</Element>
  );
};

Text.defaultProps = {
  children: undefined,
};

export default Text;
