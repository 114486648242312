import React from 'react';

import Text from 'components/atoms/Text';

export interface DataTableTypes {
  title: string;
  sup: number;
  sp: number;
  mc: number;
  logistic: number;
}

interface TableProps {
  dataTable: DataTableTypes[];
}

const Table: React.FC<TableProps> = ({ dataTable }) => (
  <table className="t-table">
    <thead>
      <tr>
        <th>
          <Text modifiers={['14x20', '600', 'seaGreen', 'center']}>
            NV của Dự án
          </Text>
        </th>
        <th>
          <Text modifiers={['14x20', '600', 'center', 'white']}>
            SUP
          </Text>
        </th>
        <th>
          <Text modifiers={['14x20', '600', 'center', 'white']}>
            SP
          </Text>
        </th>
        <th>
          <Text modifiers={['14x20', '600', 'center', 'white']}>
            MC
          </Text>
        </th>
        <th>
          <Text modifiers={['14x20', '600', 'center', 'white']}>
            LOGISTIC
          </Text>
        </th>
      </tr>
    </thead>
    <tbody>
      {
        dataTable.map((ele, index) => (
          <tr key={`table-${index.toString()}`}>
            {
              Object.keys(ele).map((item, idx) => (
                <td key={`tr-${idx.toString()}`}>
                  <Text modifiers={['14x20', '600', 'center', idx === 0 ? 'seaGreen' : 'gray']}>
                    {ele[item as keyof DataTableTypes]}
                  </Text>
                </td>
              ))
            }
          </tr>
        ))
      }
    </tbody>
  </table>
);

export default Table;
