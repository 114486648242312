import React from 'react';

import Text from '../Text';

import mapModifiers from 'utils/functions';

export type OptionsBackground = 'green' | 'yellow' | 'gray' | 'red';

interface StatusLabelProps {
  label?: string,
  modifiers?: OptionsBackground,
}

const StatusLabel: React.FC<StatusLabelProps> = ({
  label,
  modifiers,
}) => (
  <div className={mapModifiers('a-statusLabel', modifiers)}>
    <Text modifiers={['600', '14x20', 'center']} content={label} />
  </div>
);

export default StatusLabel;
