import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import ProjectProfileContentContainer from './content';
import ProjectProfileInfoContainer from './info';
import ProjectProfileTabsContainer from './tabs';

import Heading from 'components/atoms/Heading';
import Loading from 'components/atoms/Loading';
import { getProjectInfoService } from 'services/profiles';

const Profile: React.FC = () => {
  //* Hooks
  const { search } = useLocation();
  const projectCode = new URLSearchParams(search).get('projectCode');

  //* States
  const [activeStatus, setActiveStatus] = useState(0);
  const [isNotFound, setIsNotFound] = useState(false);

  //* React query
  const { data: projectInfoRes, isFetching: infoLoading } = useQuery(
    ['project-profiles', 'info', projectCode],
    () => getProjectInfoService({
      projectCode: projectCode || '',
    }),
    {
      enabled: !!projectCode,
      onError: (err) => {
        if (Array.isArray(err) && err.length > 0) {
          if (err[0].code === 404) {
            setIsNotFound(true);
          }
        }
      }
    }
  );

  if (!projectCode) {
    return (
      <div className="p-profile_invalid">
        <Heading modifiers={['24x32', '700', 'center']}>
          Đường dẫn không hợp lệ, xin vui lòng thử lại!
        </Heading>
      </div>
    );
  }

  if (isNotFound) {
    return (
      <div className="p-profile_invalid">
        <Heading modifiers={['24x32', '700', 'center']}>
          Không tìm thấy dự án mã
          {' '}
          {projectCode || ''}
          , xin vui lòng thử lại!
        </Heading>
      </div>
    );
  }

  return (
    <div className="p-profile">
      <div className="p-profile_info">
        {(infoLoading) && (
          <div className="p-profile_loading">
            <Loading isShow />
          </div>
        )}
        <ProjectProfileInfoContainer
          projectInfo={projectInfoRes?.project}
          personnels={projectInfoRes?.personnels}
        />
      </div>
      <div className="p-profile_wrapContent">
        <ProjectProfileTabsContainer
          activeStatus={activeStatus}
          statuses={projectInfoRes?.statuses}
          handleChangeStatus={setActiveStatus}
        />
        <div className="p-profile_tabPanel">
          <ProjectProfileContentContainer
            projectCode={projectCode}
            activeStatus={activeStatus}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(Profile);
