import React from 'react';

import ProfileCard, { ProfileCardProps } from 'components/molecules/ProfileCard';
import useScrollInfinite from 'hooks/useScrollInfinite';

export interface ListProfileCardProps {
  activeProfileId?: number;
  dataListProfile?: ProfileCardProps[],
  handleLoadMore?: () => void;
  handleGetIndexActive?: (id: number) => void
}

const ListProfileCard: React.FC<ListProfileCardProps> = ({
  activeProfileId,
  dataListProfile,
  handleLoadMore,
  handleGetIndexActive,
}) => {
  const { setNode } = useScrollInfinite(handleLoadMore);
  return (
    <div className="o-listProfileCard">
      {
        dataListProfile?.map((item, idx) => (
          <div key={`table-${idx.toString()}`} className="o-listProfileCard_item">
            <ProfileCard
              {...item}
              active={item.id === activeProfileId}
              handleClick={() => {
                if (handleGetIndexActive) handleGetIndexActive(item.id || 0);
              }}
            />
          </div>
        ))
      }
      {handleLoadMore && (
        <div
          className="o-listProfileCard_loadMore"
          ref={(suggest) => setNode(suggest)}
        />
      )}
    </div>
  );
};

export default ListProfileCard;
