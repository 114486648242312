import dayjs from 'dayjs';
import React, { useState } from 'react';

import InformationDetail, { InformationDetailProps } from 'components/organisms/InformationDetail';
import ModalImage from 'components/templates/ModalImage';
import { ProfileData } from 'services/profiles/types';
import { convertHeight, getImageURL } from 'utils/functions';

export interface ProjectProfileDetailContainerProps
  extends Pick<InformationDetailProps, 'loading' | 'handleAction'> {
  detail?: ProfileData,
}

const ProjectProfileDetailContainer: React.FC<ProjectProfileDetailContainerProps> = ({
  detail,
  ...handlers
}) => {
  //* States
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);

  return (
    <>
      {
        detail && (
          <InformationDetail
            image={detail.picture?.profilePicture?.map((ele) => getImageURL(ele)) || []}
            idDocument={detail?.pivot.pgId}
            id={detail?.pivot.pgId}
            position={detail.role || []}
            name={detail?.name}
            height={convertHeight(Number(detail?.height))}
            weight={`${Number(detail?.weight)}kg`}
            age={detail?.birthday ? dayjs(new Date()).diff(detail?.birthday, 'year').toString() : ''}
            performenceRating={detail.performenceRating?.toString()}
            experience={detail.hvnExperience}
            otherExperiences={detail.otherExperience}
            note={detail?.pivot.note}
            statusCode={Number(detail?.pivot.status)}
            handleClickImage={() => { setIsShowPopup(true); }}
            {...handlers}
          />
        )
      }
      <ModalImage
        isOpen={!!isShowPopup}
        handleClose={() => setIsShowPopup(false)}
        listImage={detail?.picture?.profilePicture?.map((ele) => getImageURL(ele)) || []}
      />
    </>
  );
};

export default React.memo(ProjectProfileDetailContainer);
