import React, { useMemo } from 'react';

import ProjectProfileInfo from 'components/templates/ProjectProfileInfo';
import { PersonnelsData, ProjectData } from 'services/profiles/types';
import { ObjectEntries } from 'utils/functions';

enum PersonnelsCode {
  demand = 'Nhu cầu',
  approved = 'Đã duyệt',
  backup = 'Backup',
  need = 'Còn thiếu'
}

interface ProjectProfileInfoContainerProps {
  projectInfo?: ProjectData;
  personnels?: PersonnelsData;
}

const ProjectProfileInfoContainer: React.FC<ProjectProfileInfoContainerProps> = ({
  projectInfo,
  personnels
}) => {
  //* Datas
  const provinceData = useMemo(() => {
    if (projectInfo) {
      return projectInfo.provinces.map((province) => (province.name)).join(', ');
    }
    return '';
  }, [projectInfo]);

  const dataTable = useMemo(() => {
    if (personnels) {
      return ObjectEntries(personnels).map(([personnelCode, value]) => ({
        title: PersonnelsCode[personnelCode as keyof typeof PersonnelsCode],
        sup: value.sup,
        sp: value.sp,
        mc: value.mc,
        logistic: value.logistic,
      }));
    }
    return [];
  }, [personnels]);

  return (
    <ProjectProfileInfo
      projectInfo={{
        label: 'Tên dự án:',
        name: projectInfo?.name
      }}
      provinceInfo={{
        label: 'Tỉnh/Thành phố:',
        name: provinceData
      }}
      tableData={dataTable}
    />
  );
};

export default React.memo(ProjectProfileInfoContainer);
