import React from 'react';

import Heading from 'components/atoms/Heading';
import Text from 'components/atoms/Text';
import Table, { DataTableTypes } from 'components/templates/Table';

interface ProjectProfileInfoProps {
  projectInfo: {
    label?: string;
    name?: string;
  };
  provinceInfo: {
    label?: string;
    name?: string;
  };
  tableData: DataTableTypes[];
}

const ProjectProfileInfo: React.FC<ProjectProfileInfoProps> = (
  { projectInfo, provinceInfo, tableData }
) => (
  <div className="t-projectProfileInfo">
    <div className="t-projectProfileInfo_left">
      <div className="t-projectProfileInfo_row">
        <div className="t-projectProfileInfo_row-label">
          <Text modifiers={['16x24', '600', 'manatee']}>{projectInfo.label}</Text>
        </div>
        <Heading modifiers={['24x32', '700', 'laSalleGreen']}>{projectInfo.name}</Heading>
      </div>
      <div className="t-projectProfileInfo_row">
        <div className="t-projectProfileInfo_row-label">
          <Text modifiers={['16x24', '600', 'manatee']}>{provinceInfo.label}</Text>
        </div>
        <Heading modifiers={['24x32', '700', 'laSalleGreen']}>{provinceInfo.name}</Heading>
      </div>
    </div>
    <div className="t-projectProfileInfo_right">
      <Table dataTable={tableData} />
    </div>
  </div>
);

ProjectProfileInfo.defaultProps = {
};

export default ProjectProfileInfo;
