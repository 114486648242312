import React, { useState } from 'react';

import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import Carousel, { NextArrow, PrevArrow } from 'components/organisms/Carousel';
import CustomModal from 'components/organisms/Modal';

interface ModalImageProps {
  listImage?: string[];
  isOpen: boolean;
  handleClose?: () => void;
}

const ModalImage: React.FC<ModalImageProps> = ({ listImage, isOpen, handleClose }) => {
  const [slideActive, setSlideActive] = useState(1);

  const settingModal = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <PrevArrow customArrow="white" />,
    nextArrow: <NextArrow customArrow="white" />,
    infinite: false,
    cssEase: 'ease-in-out',
    afterChange: (next: number) => setSlideActive(next + 1)
  };
  return (
    <CustomModal isOpen={isOpen} handleClose={handleClose} showIconClose>
      <div className="t-modalImage">
        <Carousel settings={settingModal}>
          {
            listImage?.map((item, index) => (
              <div key={`carousel-image-${index.toString()}`} className="t-modalImage_item">
                <Image src={item} alt="image" ratio="480x640" />
              </div>
            ))
          }
        </Carousel>
        <div className="t-modalImage_slide">
          <Text modifiers={['16x24', '600', 'white']}>
            {slideActive}
            /
            {listImage?.length}
          </Text>
        </div>
      </div>
    </CustomModal>
  );
};

export default ModalImage;
