import React, { useMemo } from 'react';

import Tabs, { Tab } from 'components/organisms/Tabs';
import { StatusesData } from 'services/profiles/types';
import { ObjectEntries } from 'utils/functions';

enum StatusCodeName {
  not_yet_approve = 'Chưa duyệt',
  approved = 'Đã duyệt',
  backup = 'Backup',
  reject = 'Không duyệt'
}

enum StatusCodeContants {
  not_yet_approve = 0,
  approved = 1,
  backup = 2,
  reject = 3
}

interface ProjectProfileTabsContainerProps {
  activeStatus: number;
  statuses?: StatusesData;
  handleChangeStatus: (statusCode: number) => void;
}

const ProjectProfileTabsContainer: React.FC<ProjectProfileTabsContainerProps> = ({
  activeStatus,
  statuses,
  handleChangeStatus
}) => {
  //* Datas
  const tabsData = useMemo(() => {
    if (statuses) {
      return ObjectEntries(statuses).map(([code, value]) => ({
        id: StatusCodeContants[code],
        label: `${StatusCodeName[code]} (${Number(value)})`,
      }));
    }
    return [];
  }, [statuses]);

  return (
    <Tabs variableMutate={activeStatus}>
      {tabsData.map((item, index) => (
        <Tab
          key={`tab-${index.toString()}`}
          label={item.label}
          active={index === activeStatus}
          handleClick={() => {
            handleChangeStatus(item.id);
          }}
        />
      ))}
    </Tabs>
  );
};

export default React.memo(ProjectProfileTabsContainer);
