import React, {
  useState,
  useMemo,
} from 'react';
import { Controller } from 'react-hook-form';

import Carousel, { NextArrow, PrevArrow } from '../Carousel';

import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';
import StatusLabel, { OptionsBackground } from 'components/atoms/StatusLabel';
import Text from 'components/atoms/Text';
import TextArea from 'components/atoms/Textarea';
import { PG_STATUS } from 'utils/constant';

export interface InformationDetailProps {
  idDocument?: number,
  id?: number,
  position?: string[],
  name?: string,
  height?: string,
  weight?: string,
  age?: string,
  performenceRating?: string,
  experience?: string,
  otherExperiences?: string,
  image: string[],
  note?: string,
  statusCode: number;
  loading?: boolean;
  handleAction?: (status: number) => void;
  handleClickImage?: (idxActive: number) => void;
}

const InformationDetail: React.FC<InformationDetailProps> = ({
  position,
  name,
  height,
  weight,
  age,
  performenceRating,
  experience,
  otherExperiences,
  image,
  note,
  idDocument,
  statusCode,
  handleAction,
  handleClickImage,
}) => {
  //* States
  const [slideActive, setSlideActive] = useState(0);

  const setting = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <PrevArrow customArrow="green" />,
    nextArrow: <NextArrow customArrow="green" />,
    infinite: false,
    cssEase: 'ease-in-out',
    afterChange: (next: number) => setSlideActive(next)
  };

  const labelData = useMemo(() => {
    let label = '';
    let color = '';
    switch (statusCode) {
      case PG_STATUS.PG_STATUS_NOT_APPROVE_YET:
        label = 'Chưa duyệt';
        color = 'gray';
        break;
      case PG_STATUS.PG_STATUS_BACKUP:
        label = 'Backup';
        color = 'yellow';
        break;

      case PG_STATUS.PG_STATUS_APPROVED:
        label = 'Đã duyệt';
        color = 'green';
        break;
      default:
        label = 'Không duyệt';
        color = 'red';
        break;
    }
    return {
      label,
      color,
    };
  }, [statusCode]);

  return (
    <div className="o-informationDetail">
      <div className="o-informationDetail_title">
        <div className="o-informationDetail_title-label">
          <Text modifiers={['20x28', '700', 'eerieBlack']}>
            Mã hồ sơ:
            {' '}
            {idDocument}
          </Text>
        </div>
        <StatusLabel label={labelData.label} modifiers={labelData.color as OptionsBackground} />
      </div>
      <div className="o-informationDetail_content">
        <div className="o-informationDetail_left">
          {!!image?.length && (
            <>
              <Carousel innerDots settings={setting}>
                {
                  image.map((item, idx) => (
                    <div
                      key={`o-informationDetail-${idx.toString()}`}
                      className="o-informationDetail_image"
                      onClick={() => handleClickImage && handleClickImage(idx)}
                    >
                      <Image ratio="382x506" src={item} size="cover" alt={`${name}-${idx}`} />
                    </div>
                  ))
                }
              </Carousel>
              <div className="o-informationDetail_left-slide">
                <Text modifiers={['16x24', '600', 'white']}>
                  {slideActive + 1}
                  /
                  {image?.length}
                </Text>
              </div>
            </>
          )}
        </div>
        <div className="o-informationDetail_right">
          {
            position && (
              <div className="o-informationDetail_wrapText">
                <Text modifiers={['gray', '14x20', '600']} content="Vị trí:" />
                <div className="o-informationDetail_positionWrapper">
                  {position.map((item, idx) => (
                    <div key={`role${idx.toString()}`} className="o-informationDetail_position">
                      <Text modifiers={['14x20', '600', 'laSalleGreen']} content={item} />
                    </div>
                  ))}
                </div>
              </div>
            )
          }
          <div className="o-informationDetail_wrapText">
            <Text modifiers={['gray', '14x20', '600']} content="Họ và tên:" />
            <div className="o-informationDetail_text">
              <Text modifiers={['eerieBlack', '14x20', '600']} content={name} />
            </div>
          </div>
          <div className="o-informationDetail_wrapText">
            <Text modifiers={['gray', '14x20', '600']} content="Chiều cao:" />
            <div className="o-informationDetail_text">
              <Text modifiers={['eerieBlack', '14x20', '600']} content={height} />
            </div>
          </div>
          <div className="o-informationDetail_wrapText">
            <Text modifiers={['gray', '14x20', '600']} content="Cân nặng:" />
            <div className="o-informationDetail_text">
              <Text modifiers={['eerieBlack', '14x20', '600']} content={weight} />
            </div>
          </div>
          <div className="o-informationDetail_wrapText">
            <Text modifiers={['gray', '14x20', '600']} content="Tuổi:" />
            <div className="o-informationDetail_text">
              <Text modifiers={['eerieBlack', '14x20', '600']} content={age} />
            </div>
          </div>
          <div className="o-informationDetail_wrapText">
            <Text modifiers={['gray', '14x20', '600']} content="Performence rating:" />
            <div className="o-informationDetail_text">
              <Text modifiers={['eerieBlack', '14x20', '600']} content={performenceRating} />
            </div>
          </div>
          <div className="o-informationDetail_wrapText">
            <Text modifiers={['gray', '14x20', '600']} content="Kinh nghiệm làm HVN:" />
            <div className="o-informationDetail_text">
              <Text modifiers={['eerieBlack', '14x20', '600']} content={experience} />
            </div>
          </div>
          <div className="o-informationDetail_wrapText">
            <Text modifiers={['gray', '14x20', '600']} content="Kinh nghiệm khác:" />
            <div className="o-informationDetail_text">
              <Text modifiers={['eerieBlack', '14x20', '600']} content={otherExperiences} />
            </div>
          </div>
          <div className="o-informationDetail_input">
            <Controller
              name="note"
              render={({ field, fieldState: { error } }) => (
                <TextArea
                  {...field}
                  label="Ghi chú"
                  placeholder="Viết ghi chú"
                  id="note"
                  handleOnchange={field.onChange}
                  error={error?.message}
                />
              )}
            />
            {note && (
              <div className="o-informationDetail_wrapNote">
                <Text modifiers={['eerieBlack', '14x20', '600']} content="Nội dung ghi chú:" />
                <div className="o-informationDetail_note">
                  <Text modifiers={['eerieBlack', '12x16', '500']} content={note} />
                </div>
              </div>
            )}
          </div>
          <div className="o-informationDetail_button">
            {statusCode !== PG_STATUS.PG_STATUS_REJECT && (
              <div className="o-informationDetail_item">
                <Button
                  modifier="denied"
                  iconModifier={{
                    iconName: 'dismiss',
                    iconSize: '20'
                  }}
                  onClick={() => handleAction && handleAction(PG_STATUS.PG_STATUS_REJECT)}
                >
                  Không duyệt
                </Button>
              </div>
            )}
            {statusCode !== PG_STATUS.PG_STATUS_BACKUP && (
              <div className="o-informationDetail_item">
                <Button
                  modifier="preventive"
                  iconModifier={{
                    iconName: 'bookmark',
                    iconSize: '20'
                  }}
                  onClick={() => handleAction && handleAction(PG_STATUS.PG_STATUS_BACKUP)}
                >
                  Dự phòng
                </Button>
              </div>
            )}
            {statusCode !== PG_STATUS.PG_STATUS_APPROVED && (
              <div className="o-informationDetail_item">
                <Button
                  modifier="successful"
                  iconModifier={{
                    iconName: 'checkmark',
                    iconSize: '20'
                  }}
                  onClick={() => handleAction && handleAction(PG_STATUS.PG_STATUS_APPROVED)}
                >
                  Duyệt
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default InformationDetail;
