import React from 'react';

import mapModifiers from 'utils/functions';

export const iconList = {
  checkmark: 'checkmark',
  bookmark: 'bookmark',
  dismiss: 'dismiss',
  close: 'close',
};

export type IconName = keyof typeof iconList;

export type IconSize =
  | '14'
  | '18'
  | '20'
  | '24'
  | '48';
export interface IconProps {
  iconName?: IconName;
  iconSize?: IconSize;
}

const Icon: React.FC<IconProps> = ({ iconSize, iconName }) => (
  <i className={mapModifiers('a-icon', iconName, iconSize)} />
);

Icon.defaultProps = {
  iconName: undefined,
  iconSize: undefined,
};

export default Icon;
