import {
 GetProjectInfoParams,
 GetProjectProfilesParams,
 ProjectInfoData,
 ProjectProfilesData,
 UpdateProfileParamsTypes
} from './types';

import axiosInstance from 'services/common/instance';

export const getProjectInfoService = async (params: GetProjectInfoParams)
: Promise<ProjectInfoData> => {
  const { projectCode } = params;
  const res = await axiosInstance.get(`profiles/project-info/${projectCode}`);
  return res.data;
};

export const getProjectProfilesService = async (params: GetProjectProfilesParams)
: Promise<ProjectProfilesData> => {
  const { projectCode, ...rest } = params;
  const res = await axiosInstance.get(`profiles/projects/${projectCode}`, { params: rest });
  return res.data;
};

export const updateProfilePGService = async (data: UpdateProfileParamsTypes): Promise<any> => {
  const { projectCode, requestFormData, spId } = data;
  const res = await axiosInstance.put(`profiles/projects/${projectCode}/profile/${spId}`, requestFormData);
  return res.data;
};
