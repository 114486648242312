import React from 'react';

import Icon, { IconProps } from 'components/atoms/Icon';
import Text from 'components/atoms/Text';
import mapModifiers from 'utils/functions';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  iconModifier?: IconProps;
  modifier?: 'successful' | 'denied' | 'preventive' | 'pending';
}

const Button: React.FC<ButtonProps> = ({
  children, iconModifier, modifier, ...props
}) => (
  <button
    type="button"
    className={mapModifiers('a-button', modifier)}
    {...props}
  >
    {iconModifier?.iconName && (
      <div className="a-button_icon">
        <Icon {...iconModifier} />
      </div>
    )}
    <div className="a-button_text">
      <Text modifiers={['600', '14x20', 'inter']}>
        {children}
      </Text>
    </div>
  </button>
);

Button.defaultProps = {
  children: undefined,
};

export default Button;
