import dayjs from 'dayjs';
import React, { useMemo } from 'react';

import ListProfileCard, { ListProfileCardProps } from 'components/organisms/ListProfileCard';
import { ProfileData } from 'services/profiles/types';
import { getImageURL } from 'utils/functions';

export interface ProjectProfileCardsContainerProps extends Omit<ListProfileCardProps, 'dataListProfile'> {
  profiles?: ProfileData[],
}

const ProjectProfileCardsContainer: React.FC<ProjectProfileCardsContainerProps> = ({
  profiles,
  ...props
}) => {
  //* Datas
  const profileCardList = useMemo(() => {
    if (profiles) {
      return profiles.map((item) => (
        {
          id: item.pivot.pgId,
          img: item.picture?.profilePicture?.[0] ? getImageURL(item.picture.profilePicture[0]) : '',
          name: item?.name,
          age: item?.birthday ? dayjs(new Date()).diff(item?.birthday, 'year').toString() : '',
          height: Number(item.height),
          weight: Number(item.weight),
        }
      ));
    }
    return [];
  }, [profiles]);

  return (
    <ListProfileCard
      {...props}
      dataListProfile={profileCardList}
    />
  );
};

export default React.memo(ProjectProfileCardsContainer);
